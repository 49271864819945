import React from 'react'
import { ASSETS } from '@helpers'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import {
  TGIcon,
  FBIcon,
  Footer,
  IGIcon,
  Layout,
  MainTitle,
  MetaHeader,
  Breadcrumb,
  ShopeeIcon,
  DownloadCatalog,
} from '@components'

const title = 'Buku Edu Online'
const subtitle = 'Jom tengok koleksi buku-buku kami'

export default function Books({ data }: any) {
  const {
    allMdx: { nodes },
    site: {
      siteMetadata: {
        domain,
        social: {
          buku: { fb, ig, shopee, tg },
        },
      },
    },
  } = data

  return (
    <Layout>
      <MetaHeader
        title={title}
        description={subtitle}
        imgUrl={`${domain}${ASSETS.bookBanner}`}
      />
      <div className='min-h-screen flex items-center justify-center'>
        <div className='flex-1 max-w-4xl mx-auto p-2 md:p-10'>
          <Breadcrumb />

          <MainTitle title={title} subtitle={subtitle} />

          <div className='my-10'>
            <DownloadCatalog />
          </div>

          <ul className='max-w-xs mx-auto sm:max-w-none grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4'>
            {nodes.map(({ id, ...i }: { id: string } | any) => (
              <Boxes key={id} item={i} />
            ))}
          </ul>
        </div>
      </div>

      <Footer>
        <li>
          <IGIcon ig={ig} />
        </li>
        <li>
          <FBIcon fb={fb} />
        </li>
        <li>
          <ShopeeIcon shopee={shopee} />
        </li>
        <li>
          <TGIcon tg={tg} />
        </li>
      </Footer>
    </Layout>
  )
}

const Boxes = ({ item }: { id: string } | any) => {
  const {
    slug,
    frontmatter: {
      title,
      img: {
        childImageSharp: { gatsbyImageData },
      },
    },
  } = item

  return (
    <li className='bg-white rounded shadow-md hover:shadow-lg border'>
      <Link className='flex flex-col h-full' to={`/buku-buku/${slug}`}>
        <GatsbyImage
          image={gatsbyImageData}
          alt={title}
          imgStyle={{
            objectFit: 'contain',
          }}
          style={{
            height: '11em',
            width: '100%',
          }}
        />
        <div className='h-12 text-sm text-center flex items-center flex-grow justify-center'>
          {title}
        </div>
      </Link>
    </li>
  )
}

export const query = graphql`
  {
    site {
      siteMetadata {
        domain
        social {
          buku {
            tg
            fb
            ig
            shopee
          }
        }
      }
    }
    allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { fileAbsolutePath: { regex: "/contents/buku-buku/" } }
    ) {
      nodes {
        id
        slug
        frontmatter {
          title
          date(formatString: "DD/MMM/YYYY")
          img {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`
